import { useEffect } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import GetFlag from 'src/utils/getFlags';
import useAuth from 'src/hooks/useAuth';
import CustomSelect from 'src/components/CustomComponents/CustomSelect';

const languageOptions = {
  en: { icon: 'united kingdom', label: 'English', value: 'English' },
  la: { icon: 'latvia', label: 'Latviešu', value: 'Latvian' },
  esp: { icon: 'spain', label: 'Español', value: 'Spanish' },
  pt: { icon: 'portugal', label: 'Português', value: 'Portuguese' },
  ja: { icon: 'japan', label: '日本語', value: 'Japanese' },
  ee: { icon: 'estonia', label: 'eesti keel', value: 'Estonian' },
  fr: { icon: 'france', label: 'Français', value: 'French' },
  pl: { icon: 'poland', label: 'Polski', value: 'Polish (Polski)' },
  ka: { icon: 'Georgia', label: 'Georgian', value: 'Georgian' },
  ru: { icon: 'Russia', label: 'Русский', value: 'Russian' },
  cs: { icon: 'czech republic', label: 'Čeština', value: 'Czech' }, // Czech language added
  hr: { icon: 'croatia', label: 'Hrvatski', value: 'Croatian' },
};

const LanguagePopover: FC = () => {
  const { t, i18n } = useTranslation();
  const { language, lang, platformData } = useAuth();

  const allowedLanguages = platformData?.language ? [...new Set([...JSON.parse(platformData?.language)])] : Object.values(languageOptions)?.map((option) => option?.value);

  // Filter language options based on allowed languages
  const filteredLanguageEntries = Object.entries(languageOptions)?.filter(([_, value]) => allowedLanguages?.includes(value?.value));

  // Check if English ('en') is already in the filtered languages
  const isEnglishPresent = filteredLanguageEntries?.some(([key]) => key === 'en');

  // If English is not present, add it to the second position
  if (!isEnglishPresent) {
    const englishEntry = Object.entries(languageOptions)?.find(([key, _]) => key === 'en');
    if (englishEntry) {
      filteredLanguageEntries?.splice(1, 0, englishEntry); // Insert English entry at the second index
    }
  }

  const filteredLanguageOptions = Object.fromEntries(filteredLanguageEntries);

  // Get the first language key from the available options
  const defaultLang = Object.keys(filteredLanguageOptions)[0];

  const handleChangeLanguage = (lang: string): void => {
    i18n.changeLanguage(lang);
    language(lang);
  };

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    } else {
      const savedLang = Cookies.get('lang') || defaultLang; // Get saved language or default to the first language
      Cookies.set('lang', savedLang);
      i18n.changeLanguage(savedLang);
      language(savedLang); // Update the auth state with the selected language
    }
  }, [lang, defaultLang]);

  return (
    <CustomSelect
      placeholder={t('Language')}
      selectedValue={i18n.language}
      options={Object.keys(filteredLanguageOptions)?.map((language) => ({
        value: language,
        leftIcon: GetFlag(filteredLanguageOptions[language]?.icon !== 'undefined' ? filteredLanguageOptions[language]?.icon : '', {
          width: '15px',
          height: '15px',
          display: 'flex',
          alignItems: 'center',
          marginRight: '2px',
        }),
        title: filteredLanguageOptions[language]?.label,
      }))}
      onChange={(value) => handleChangeLanguage(value?.toString())}
      buttonProps={{
        variant: 'transparent',
      }}
    />
  );
};

export default LanguagePopover;
