import React, { LegacyRef, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { Box, Checkbox, Tooltip, Typography, makeStyles, ButtonBase, Stack } from '@material-ui/core';
import chroma from 'chroma-js';
import AuthContext from 'src/contexts/JWTContext';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import CustomPopover from 'src/components/CustomComponents/CustomPopover';
import IconGroup from './SelectedSports';
import clsx from 'clsx';
import { useQuery } from '@apollo/client';
import { SPORTSWITHCOUNT } from '../../graphql/queries';
import ScrollContainer from 'react-indiana-drag-scroll';
import { PLATFORMID, devUrl, localUrl, prodUrl, prodUrl2, prodUrl3 } from 'src/constants';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  container: {
    display: 'inline-flex',
    padding: '20px',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
    borderRadius: '6px',
    border: '1px solid ##0000001A',
    background: '#FFF',
    boxShadow: '30px 30px 46px 0px rgba(0, 0, 0, 0.10)',
    width: '100%',
    height: '100%',
  },
  sectionHeading: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sectionCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
  },
  title: {
    color: '#0A2540',
    fontFamily: 'Eudoxus Sans',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
  },
  card: {
    maxWidth: '216px',
    width: '100%',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '12px',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
    whiteSpace: 'nowrap',
  },
  heading: {
    color: '#0A2540',
    fontFamily: 'Eudoxus Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  subHeading: {
    color: '#0A2540',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: 'normal',
    opacity: '35%',
  },
  sportItem: {
    transition: 'all 0.2s',
    border: `1px solid transparent`,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    gap: '15px',
    alignItems: 'center',
    borderRadius: '4px',
    height: '50px',
    minHeight: '50px',
    padding: '0px 12px 0px 10px',
    cursor: 'pointer',
    '& .MuiTouchRipple-child': {
      backgroundColor: process.env.REACT_APP_PRIMARY_COLOR,
    },
    '&:hover': {
      background: '#0A25400D',
    },
  },
  selectAll: {
    minHeight: 0,
    maxHeight: '32px',
    overflow: 'hidden',
    background: '#0A25400D',
  },
  sportItemActive: {
    borderColor: process.env.REACT_APP_PRIMARY_COLOR,
    background: chroma(process.env.REACT_APP_PRIMARY_COLOR).alpha(0.07).hex(),
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    gap: '15px',
    alignItems: 'center',
    borderRadius: '4px',
    height: '50px',
    padding: '0px 12px 0px 10px',
    '&:hover': {
      background: chroma(process.env.REACT_APP_PRIMARY_COLOR).alpha(0.03).hex(),
    },
  },
  scrollContainer: {
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));

const SportSelector = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setSelectedSports, selectedSports } = useContext(AuthContext);
  const [highlightedSports, setHighlightedSports] = useState([]);
  const [isEndVisible, setIsEndVisible] = useState(false);
  const [bottomRef, setBottomRef] = useState<HTMLDivElement>(null);
  const ref = useRef<ScrollContainer>(null);

  const platformId =
    window.location.href?.includes(prodUrl) ||
    window.location.href?.includes(prodUrl2) ||
    window.location.href?.includes(prodUrl3) ||
    window.location.href?.includes(devUrl) ||
    window.location.href?.includes(localUrl)
      ? PLATFORMID
      : parseInt(process.env.REACT_APP_PLATFORM_ID);
  const { loading: areSportsLoading, data } = useQuery(SPORTSWITHCOUNT, {
    variables: {
      platform: platformId,
    },
  });

  const sportsData = data?.sportsWithTournamentCount;

  useEffect(() => {
    if (!selectedSports.length && sportsData) {
      setSelectedSports(sportsData.map((item) => item.id));
    }

    setHighlightedSports(sportsData?.filter((item) => selectedSports.includes(item.id)));
  }, [selectedSports, sportsData]);

  const handleChange = (sport) => {
    setHighlightedSports((curr) => {
      if (curr.some((item) => item.id === sport.id)) {
        return curr.filter((item) => item.id !== sport.id);
      } else {
        return [...curr, sport];
      }
    });
  };

  const handleSelectAll = () => {
    setHighlightedSports((prev) => (prev.length === sportsData?.length ? [] : sportsData));
  };

  const handleSave = () => {
    const newSelectedSports = highlightedSports.map((item) => item.id);
    if (JSON.stringify(newSelectedSports) !== JSON.stringify(selectedSports)) {
      setSelectedSports(newSelectedSports);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsEndVisible(entry.isIntersecting);
      },
      {
        threshold: 0,
      }
    );

    if (bottomRef) {
      observer.observe(bottomRef);
    }

    return () => {
      if (bottomRef) {
        observer.unobserve(bottomRef);
      }
    };
  }, [bottomRef]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <CustomPopover
      onClose={handleSave}
      triggerEl={<IconGroup sports={sportsData?.filter((item) => selectedSports.includes(item.id))} />}
      preventClosing={highlightedSports?.length === 0 ? () => toast.error('Please select at least one sport') : undefined}
      modalOnMobile
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', flex: 1, gap: '10px' }}>
        <Box sx={{ minHeight: '30px' }}>
          <ButtonBase className={clsx(classes.sportItem, classes.selectAll, highlightedSports?.length === sportsData?.length ? classes.sportItemActive : undefined)} onClick={handleSelectAll}>
            <Typography variant="font21" sx={{ lineHeight: '18px', color: '#0A2540' }}>
              {t('Select All')}
            </Typography>
            <Checkbox size="small" checked={highlightedSports?.length === sportsData?.length} sx={{ pointerEvents: 'none' }} />
          </ButtonBase>
        </Box>
        <ScrollContainer
          ref={ref as LegacyRef<ScrollContainer> & ReactNode}
          hideScrollbars={isMobile}
          vertical
          style={{
            transition: 'all 0.4s',
            maxHeight: isMobile ? 'none' : '325px',
            ...(isEndVisible
              ? {}
              : {
                  maskImage: `linear-gradient(to bottom, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0))`,
                  WebkitMaskImage: `linear-gradient(to bottom, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0))`,
                }),
          }}
          className={classes.scrollContainer}
        >
          <Box className={classes.column} sx={{ position: 'relative' }}>
            {sportsData?.map((sport) => {
              const isSportSelected = highlightedSports?.some((item) => item.id === sport?.id);
              return (
                <ButtonBase className={clsx(classes.sportItem, isSportSelected ? classes.sportItemActive : undefined)} onClick={() => handleChange(sport)} key={sport?.id}>
                  <Box className={classes.card}>
                    <div
                      style={{
                        minHeight: '28px',
                        minWidth: '28px',
                        backgroundColor: isSportSelected ? process.env.REACT_APP_PRIMARY_COLOR : '#0A2540',
                        WebkitMask: `url(${sport?.icon || '/images/updated/volley.svg'}) no-repeat center`,
                        mask: `url(${sport?.icon || '/images/updated/volley.svg'}) no-repeat center`,
                        maskSize: '24px 24px',
                      }}
                    />
                    <Box className={classes.details}>
                      <Typography
                        variant="font18"
                        sx={{
                          whiteSpace: 'nowrap',
                          lineHeight: '18px',
                          color: '#0A2540',
                        }}
                      >
                        {t(sport?.title)}
                      </Typography>
                      <Typography className={classes.subHeading}>{`${sport?.tournamentCount} ${t('Tournaments')}`}</Typography>
                    </Box>
                  </Box>
                  <Checkbox
                    size="small"
                    checked={isSportSelected}
                    sx={{
                      '& .MuiCheckbox-root': {
                        pointerEvents: 'none',
                        borderRadius: '50%', // Make it rounder
                        border: '2px solid #0A2540', // Set border color
                      },
                    }}
                  />{' '}
                </ButtonBase>
              );
            })}
            <div
              ref={(ref) => setBottomRef(ref)}
              style={{
                height: '20px',
                width: '100%',
                position: 'absolute',
                bottom: 0,
                visibility: 'hidden',
              }}
            />
          </Box>
        </ScrollContainer>
        <Tooltip title="Please use Live Chat to contact us">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
              padding: '0px 12px 0px 12px',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            <Typography
              variant="font21"
              sx={{
                textAlign: 'center',
                lineHeight: '13.2px',
                color: '#A9B3BC',
                width: '100%',
              }}
            >
              {t("Don't see your sport? Contact us")}
            </Typography>
          </Box>
        </Tooltip>
      </Box>
    </CustomPopover>
  );
};

export default SportSelector;
