import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useNavigate } from 'react-router';
import { EventNote, MenuOutlined, Person, Close as CloseIcon, StarOutline, ConfirmationNumberOutlined, ManageAccountsOutlined, EqualizerOutlined } from '@material-ui/icons';
import useAuth from 'src/hooks/useAuth';
import { Box, Stack, Typography } from '@material-ui/core';
import Cookies from 'js-cookie';
import CustomPaperCard from '../CustomComponents/CustomPaperCard';
import CustomButton from '../CustomComponents/CustomButton';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  root: {
    width: '100%',
    zIndex: 1,
  },
  icon: {
    width: '20px',
    height: '20px',
  },
  tabRoot: {
    maxHeight: '65px',
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-between',
    },
    '& .MuiTabs-indicator': {
      top: 0,
    },
    '& .MuiButtonBase-root': {
      padding: '0px 5px',
      fontSize: '12px',
      textTransform: 'none',
    },
  },
});

export default function TabBar({ sidebarOpen, setSidebarOpen }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const token = Cookies?.get('token');
  const [currentTabs, setCurrentTabs] = useState(['rankings', 'tournaments', 'myEntries']);

  const isLoggedIn = auth?.user?.id;
  const isManager = auth?.isManager;
  const fullPath = location.pathname + location.search;

  const tabByRole = (userTab: string, managerTab?: string, defaultTab?: string) => {
    if (isLoggedIn) {
      if (isManager) return managerTab ?? userTab;
      else return userTab;
    } else {
      return defaultTab ?? 'login';
    }
  };

  useEffect(() => {
    // Here we will be able to set up tabs based on specific tabs
    switch (location.pathname) {
      default:
        setCurrentTabs([tabByRole('rankings', 'manager'), 'tournaments', tabByRole('leagues', 'myEntries', 'leagues')]);
        break;
    }
  }, [location.pathname, isLoggedIn, isManager]);

  const tabsData = {
    tournaments: {
      icon: <EventNote className={classes.icon} />,
      text: t('Calendar'),
      path: '/tournaments',
      selected: undefined,
    },
    rankings: {
      icon: <StarOutline className={classes.icon} />,
      text: t('Rankings'),
      path: '/rankings',
      selected: undefined,
    },
    clubs: {
      icon: <Person className={classes.icon} />,
      text: t('Clubs'),
      path: '/clubs',
      selected: undefined,
    },
    myEntries: {
      icon: <ConfirmationNumberOutlined className={classes.icon} />,
      text: t('My Entries'),
      path: '/mycareer?tab=entries',
      selected: undefined,
    },
    profile: {
      icon: <Person className={classes.icon} />,
      text: t('Profile'),
      selected: undefined,
    },
    login: {
      icon: <Person className={classes.icon} />,
      text: t('Log in'),
      selected: auth.loginModalOpen,
      onClick: () => auth.setLoginModalOpen(true),
    },
    manager: {
      icon: <ManageAccountsOutlined className={classes.icon} />,
      text: 'Manager',
      onClick: () => window.open(`${process.env.REACT_APP_FE_URL}/dashboard/all-tournaments?token=${token}`),
      selected: undefined,
    },
    menu: {
      icon: sidebarOpen ? <CloseIcon className={classes.icon} /> : <MenuOutlined className={classes.icon} />,
      text: sidebarOpen ? t('Close') : t('Menu'),
      selected: sidebarOpen,
      onClick: () => setSidebarOpen((curr) => !curr),
    },
    leagues: {
      icon: <EqualizerOutlined className={classes.icon} />,
      text: t('leagues'),
      selected: undefined,
      path: '/leagues',
    },
  };

  return (
    <CustomPaperCard sx={{ borderRadius: 0, borderRight: 'none', borderLeft: 'none', borderBottom: 'none' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
        {currentTabs.map((tab) => (
          <CustomButton
            selected={tabsData[tab]?.selected ?? fullPath.startsWith(tabsData[tab]?.path)}
            onClick={() => (tabsData[tab].path ? navigate(tabsData[tab].path) : tabsData[tab].onClick?.())}
            size="auto"
            variant="transparent"
            square
            sx={{ flex: 1, height: '100%', py: '6px', borderRadius: 0 }}
          >
            <Stack sx={{ alignItems: 'center', gap: '2px' }}>
              {tabsData[tab]?.icon}
              <Typography variant="font11">{tabsData[tab]?.text?.charAt(0).toUpperCase() + tabsData[tab]?.text?.slice(1)}</Typography>
            </Stack>
          </CustomButton>
        ))}
        <CustomButton selected={tabsData.menu.selected} onClick={tabsData.menu.onClick} size="auto" variant="transparent" square sx={{ flex: 1, height: '100%', py: '6px', borderRadius: 0 }}>
          <Stack sx={{ alignItems: 'center', gap: '2px' }}>
            {tabsData.menu.icon}
            <Typography variant="font11" sx={{ textTransform: 'capitalize' }}>
              {tabsData.menu.text}
            </Typography>
          </Stack>
        </CustomButton>
      </Box>
    </CustomPaperCard>
  );
}
