import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { Backdrop, Box, Button, CircularProgress, Divider, ListItemText, MenuItem, Modal, Paper, Popover, TextField, Tooltip, Typography, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useLocation, useNavigate } from 'react-router';
import useAuth from 'src/hooks/useAuth';
import toast from 'react-hot-toast';
import { CustomWellComeMsg, LoginSocialIcons, PLATFORMID, WellComeMsg, devUrl, localUrl, prodUrl, prodUrl2, prodUrl3 } from 'src/constants';
import { LoginJWT } from 'src/components/authentication/login';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useTranslation } from 'react-i18next';
import CustomModal from 'src/components/CustomComponents/CustomModal';
import CustomButton from 'src/components/CustomComponents/CustomButton';
interface LoginModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    padding: '15px 20px 25px 20px',
    maxWidth: '530px',
    borderRadius: '12px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 1,
    zIndex: 9999,
    border: 0,
    background: '#FFF',
    outline: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  paperStyle: {
    width: '100%',
    // height: 379,
    background: '#FFF',
    boxShadow: 'none',
    position: 'relative',
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    // border:"0px 1px 0px 0px solid "
  },
  socialContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',
  },
  loginActionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    width: '100%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const WelcomeModal: FC<LoginModalProps> = ({ open, setOpen }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user, setRecoverModalOpen, setSignupModalOpen, platformData } = useAuth();
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [openList, setOpenList] = useState(false);

  const { platform } = useAuth() as any;
  const platformId =
    window.location.href?.includes(prodUrl) ||
    window.location.href?.includes(prodUrl2) ||
    window.location.href?.includes(prodUrl3) ||
    window.location.href?.includes(devUrl) ||
    window.location.href?.includes(localUrl)
      ? PLATFORMID
      : parseInt(process.env.REACT_APP_PLATFORM_ID);
  const location = useLocation();
  const navigate = useNavigate();
  const isRestrictedPath = location.pathname.startsWith('/userSettings') || location.pathname === '/mycareer' || location.pathname === '/get-licence';
  const handleClose = () => {
    if (!user && isRestrictedPath) {
      toast.error('Unauthorized , please sign in ');
    } else {
      setOpen(false);
    }
  };
  useEffect(() => {
    if (user) {
      handleClose();
    }
  }, [user]);
  useEffect(() => {
    if (!user && isRestrictedPath) {
      navigate('/');
    }
  }, [user, isRestrictedPath]);
  const handleMenuItemClick = (item) => {
    // navigate(item.path);
    handleClose();
  };
  return (
    <React.Fragment>
      <CustomModal openOvveride={open} onClose={handleClose} allowClosing={isRestrictedPath ? false : true}>
        <Paper className={classes.paperStyle}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
            <Box className={classes.loginContainer}>
              <Box className={classes.socialContainer}>
                <Box
                  sx={{
                    margin: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <Box sx={{ display: 'flex', gap: '5px' }}>
                    <img
                      src={platformData?.lightLogo}
                      style={{
                        cursor: 'pointer',
                        width: '110px',
                        height: '98px',
                        objectFit: 'contain',
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '8px',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: 'center',
                        color: '#0A2540',
                        lineHeight: '24px',
                      }}
                      variant="heading2"
                    >
                      {`${t('Welcome to')} ${platformData?.title ? platformData?.title : ''}`}
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: 'center',
                        color: '#666666',
                        lineHeight: '16.8px',
                        maxWidth: '368px',
                        width: '100%',
                      }}
                      variant="font21"
                    >
                      {`${platformData?.title ? platformData?.title : ''}, ${platformId == 1 || platformId == 3 ? t(WellComeMsg) : t(CustomWellComeMsg)}`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.loginActionContainer}>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    gap: '8px',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#0A2540',
                      lineHeight: '20px',
                      textAlign: 'center',
                    }}
                    variant="font15"
                  >
                    {t('Did you have an account in one of ')}
                    <span
                      style={{
                        color: process.env.REACT_APP_PRIMARY_COLOR,
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }}
                      ref={anchorRef}
                      onClick={() => setOpenList(true)}
                    >
                      {t('those')}
                    </span>{' '}
                    {t('platforms?')}
                  </Typography>
                  <CustomButton
                    size="medium"
                    variant="transparent"
                    shape="default"
                    sx={{
                      background: '#0000000D',
                      height: '40px',
                      borderRadius: '6px',
                      width: '100%',
                      color: '#0A2540',
                      fontFamily: 'Inter',
                      fontSize: '12px',
                      fontWeight: 600,
                      lineHeight: '20px',
                    }}
                    onClick={() => {
                      setRecoverModalOpen(true);
                      handleClose();
                    }}
                  >
                    {t('Recover password')}
                  </CustomButton>
                  <CustomButton
                    size="medium"
                    variant="primary"
                    shape="default"
                    sx={{
                      width: '100%',
                      color: '#fff',
                      borderRadius: '6px',
                      fontFamily: 'Inter',
                      fontSize: '12px',
                      fontWeight: 600,
                      lineHeight: '20px',
                    }}
                    onClick={() => {
                      setSignupModalOpen(true);
                      handleClose();
                    }}
                  >
                    {t(`newUser`)}
                  </CustomButton>
                  <CustomButton
                    size="medium"
                    variant="transparent"
                    shape="default"
                    sx={{
                      width: '100%',
                      background: '#0000000D',
                      color: '#0A2540',
                      borderRadius: '6px',
                      fontFamily: 'Inter',
                      fontSize: '12px',
                      fontWeight: 600,
                      lineHeight: '20px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    {t('Continue as a guest')}
                  </CustomButton>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '3px',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <CustomButton
                    size="small"
                    variant="outline"
                    shape="chip"
                    sx={{
                      height: '35px',
                      width: '100%',
                      justifyContent: 'center',
                    }}
                    onClick={() => window.open('https://www.instagram.com/tournated')}
                  >
                    <img src="/images/newInat.svg" />
                    <Typography sx={{ color: '#0A254059', lineHeight: '20px' }} variant="font15">
                      {t('Instagram')}
                    </Typography>
                  </CustomButton>

                  <CustomButton
                    size="small"
                    variant="outline"
                    shape="chip"
                    sx={{
                      height: '35px',
                      width: '100%',
                      justifyContent: 'center',
                    }}
                    onClick={() => window.open('https://tournated.gitbook.io/tournated')}
                  >
                    <img src="/images/updated/cat.svg" style={{ width: '15px', height: '15px' }} />
                    <Typography sx={{ color: '#0A254059', lineHeight: '20px' }} variant="font15">
                      {t('Documentation')}
                    </Typography>
                  </CustomButton>

                  <CustomButton
                    size="small"
                    variant="outline"
                    shape="chip"
                    sx={{
                      height: '35px',
                      width: '100%',
                      justifyContent: 'center',
                    }}
                    onClick={() => window.open('https://tournated.gitbook.io/tournated')}
                  >
                    <HelpOutlineIcon sx={{ height: '20px', width: '20px' }} />
                    <Typography sx={{ color: '#0A254059', lineHeight: '20px' }} variant="font15">
                      {t('Support')}
                    </Typography>
                  </CustomButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
        <Popover
          anchorEl={anchorRef.current}
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'bottom',
          }}
          keepMounted
          onClose={() => setOpenList(false)}
          open={openList}
          PaperProps={{
            sx: {
              width: 250,
              boxShadow: '0px 6px 9px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.16)',
              borderRadius: '8px',
              padding: '10px 0px',
            },
          }}
        >
          {MenuItems.map((item) => (
            <MenuItem onClick={() => handleMenuItemClick(item)} key={item.id} sx={{ padding: '0 20px' }}>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      color: '#0A2540',
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '14px',
                      // textDecoration: "underline",
                      // cursor: "pointer",
                    }}
                  >
                    {item.title}
                  </Typography>
                }
              />
            </MenuItem>
          ))}
        </Popover>
      </CustomModal>
    </React.Fragment>
  );
};
const MenuItems = [
  {
    id: 0,
    title: 'beachtennis.io',
    desc: 'In-Platform management',
    path: '/pricing?plan=manager',
  },
  {
    id: 1,
    title: 'rankiaopr.com',
    desc: 'Launch your own platform',
    path: '/pricing?plan=custom',
  },
  {
    id: 1,
    title: 'teniss.lat',
    desc: 'Launch your own platform',
    path: '/pricing?plan=custom',
  },
  {
    id: 1,
    title: 'geotennis.ge',
    desc: 'Launch your own platform',
    path: '/pricing?plan=custom',
  },
  {
    id: 1,
    title: 'sportfishing.live',
    desc: 'Launch your own platform',
    path: '/pricing?plan=custom',
  },
  {
    id: 1,
    title: 'armarkseri.lv',
    desc: 'Launch your own platform',
    path: '/pricing?plan=custom',
  },
];
export default WelcomeModal;
