import { useEffect, useRef } from 'react';

type Options = { skipFirstRender?: boolean };
const useScrollToTop = (deps: string[], skipFirstRender?: boolean) => {
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (skipFirstRender && isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    const main = document.getElementById('main');

    if (main) {
      main.scrollTo({ top: 0, left: 0 });
    }
  }, [...deps]);
};
export default useScrollToTop;
