import React, { useEffect, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router';
import useAuth from 'src/hooks/useAuth';
import AboutSection from 'src/components/authentication/register/AboutSection';
import BasicDetails from 'src/components/authentication/register/BasicDetails';
import SportPicker from 'src/components/authentication/register/SportPicker';
import AddLicense from 'src/components/authentication/register/AddLicenses';
import SelectRole from 'src/components/authentication/register/SelectRole';
import { Modals } from './AuthModal';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    padding: '15px 20px 25px 20px',
    maxWidth: '461px',
    height: 'max-content',
    maxHeight: '100%',
    minHeight: '531px',
    borderRadius: '12px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 1,
    zIndex: 9999,
    border: 0,
    background: '#FFF',
    outline: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  paperStyle: {
    width: '100%',
    height: '100%',
    background: '#FFF',
    boxShadow: 'none',
    position: 'relative',
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    // border:"0px 1px 0px 0px solid "
  },
  socialContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',
  },
  loginActionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    width: '100%',
  },
  dot: {
    height: '5px',
    width: '5px',
    backgroundColor: '#D9D9D9',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '5px',
    // cursor: "pointer",
    transition: 'background-color 0.3s ease',
  },
  active: {
    height: '5px',
    width: '8px',
    borderRadius: '1000px',
    backgroundColor: '#0A2540',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
function Dot(props) {
  const classes = useStyles();
  return <span className={`${classes.dot} ${props.active ? classes.active : ''}`} onClick={props.onClick}></span>;
}

type Props = {
  handleClose: () => void;
  setModal: (state: Modals) => void;
  backButtonClickCounter: number;
  setBackButtonClickCounter: (state: number) => void;
  setShowBackBtn: (state: boolean) => void;
};
const SignupContent = ({ handleClose, setModal, backButtonClickCounter, setShowBackBtn, setBackButtonClickCounter }: Props) => {
  const { user } = useAuth();
  const location = useLocation();
  const [currentComponent, setCurrentComponent] = useState(0);
  const isRestrictedPath = location.pathname.startsWith('/userSettings') || location.pathname === '/mycareer' || location.pathname === '/get-licence';
  const handleClick = (index) => {
    setCurrentComponent(index);
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (backButtonClickCounter > 0) {
      setCurrentComponent((prev) => Math.max(0, Math.min(prev - 1, 4)));
    }
    return () => setBackButtonClickCounter(0);
  }, [backButtonClickCounter]);

  useEffect(() => {
    if (currentComponent > 0) setShowBackBtn(true);
    return () => setShowBackBtn(false);
  }, [currentComponent]);

  useEffect(() => {
    if (user) {
      handleClose();
    }
  }, [user]);
  useEffect(() => {
    if (!user && isRestrictedPath) {
      navigate('/');
    }
  }, [user, isRestrictedPath]);
  return (
    <>
      {currentComponent === 0 && <BasicDetails handleClose={handleClose} setModal={setModal} handleClick={handleClick} />}
      {currentComponent === 1 && <SportPicker handleClose={handleClose} handleClick={handleClick} />}
      {currentComponent === 2 && <AboutSection handleClose={handleClose} handleClick={handleClick} />}
      {currentComponent === 3 && <AddLicense handleClose={handleClose} handleClick={handleClick} />}
      {currentComponent === 4 && <SelectRole handleClose={handleClose} handleClick={handleClick} />}
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Box>
          {[0, 1, 2, 3, 4].map((index) => (
            <Dot key={index} active={currentComponent === index} />
          ))}
        </Box>
      </Box>
    </>
  );
};

export default SignupContent;
