export const isArrayCheck = (arr: any) => Array.isArray(arr) && arr.length > 0;

export const findTeamByKey = (key, teams) => {
  if (!key) {
    return null;
  }

  for (const team of teams) {
    if (team[key]) {
      return team;
    }
  }

  return null;
};

export const getShortMatchStatus = (status, symbol = '', time = '', isOOP = false) => {
  switch (status) {
    case 'Followed By':
      return `FB ${symbol}${isOOP ? '' : time ?? ''}`;
    case 'Not Before':
      return `NB ${time ?? ''}`;
    case `Not before / After rest`:
      return `NB ${time ?? ''} / After rest`;
    case `Time to be announced / After rest`:
      return `TBA / After rest`;
    case `Specific Time`:
      return `${time ?? ''}`;
    default:
      return `${time ?? ''}`;
  }
};

export const parseToJSON = (str) => {
  try {
    return JSON?.parse(str);
  } catch (e) {
    return str;
  }
};

export const getCurrencySymbol = (currencyCode) => {
  switch (currencyCode) {
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    case 'ILS':
      return '₪';
    case 'GEL':
      return '₾';
    case 'AED':
      return 'د.إ';
    case 'YEN':
      return '¥';
    case 'RUB':
      return '₽';
    case 'BRAZILIAN REAL':
      return 'R$';
    case 'PLN':
      return 'zł';
    default:
      return currencyCode;
  }
};

export const secToMins = (sec) => {
  const minutes = sec / 60;
  return Number.isInteger(minutes) ? minutes.toFixed(0) + 'min' : minutes.toFixed(1) + 'min';
};

export function getFideTitle(title) {
  const fideTitles = [
    { title: 'Grandmaster', abbreviation: 'GM', color: '#963BC1' },
    { title: 'International Master', abbreviation: 'IM', color: '#E733A0' },
    { title: 'FIDE Master', abbreviation: 'FM', color: '#FF577A' },
    { title: 'Candidate Master', abbreviation: 'CM', color: '#FF8E5B' },
    { title: 'Woman Grandmaster', abbreviation: 'WGM', color: '#B43FEC' },
    {
      title: 'Woman International Master',
      abbreviation: 'WIM',
      color: '#E733A0',
    },
    { title: 'Woman FIDE Master', abbreviation: 'WFM', color: '#FF577A' },
    { title: 'Woman Candidate Master', abbreviation: 'WCM', color: '#FF8E5B' },
    { title: 'International Arbiter', abbreviation: 'IA', color: 'blue' },
    { title: 'FIDE Arbiter', abbreviation: 'FA', color: 'teal' },
    { title: 'FIDE Instructor', abbreviation: 'FI', color: 'cyan' },
    { title: 'FIDE Organiser', abbreviation: 'IO', color: 'lime' },
    { title: 'National Instructor', abbreviation: 'NI', color: 'yellow' },
    { title: 'National Arbiter', abbreviation: 'NA', color: 'red' },
    { title: 'Developmental Instructor', abbreviation: 'DI', color: 'indigo' },
  ];

  return fideTitles.find((item) => item.title?.toLowerCase() === title?.toLowerCase());
}

export const getTimeControl = (timeCont): string => {
  const timeControl: any = parseToJSON(timeCont);
  return timeControl?.title?.toLowerCase()?.trim() ?? '';
};

export const getRating = (user, timeControl) => {
  const timeCont: any = getTimeControl(timeControl);
  switch (timeCont) {
    case 'blitz':
      return user?.blitzElo === 'Notrated' ? '0' : user?.blitzElo ?? '0';
    case 'rapid':
      return user?.rapidElo === 'Notrated' ? '0' : user?.rapidElo ?? '0';
    case 'standard':
      return user?.standardElo === 'Notrated' ? '0' : user?.standardElo ?? '0';
    default:
      return '0';
  }
};

export const sanitizeFileName = (fileName: string): string => {
  return fileName
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\s+/g, '_')
    .replace(/\./g, '_')
    .replace(/,/g, '_')
    .replace(/[\/\\:*?"<>|]/g, '')
    .replace(/[^a-zA-Z0-9_.-]/g, '');
};

export const downloadFile = async (fileUrl, fileName = ''): Promise<void> => {
  let name = fileName ? fileName : fileUrl?.split('-')[fileUrl?.split('-')?.length - 1] || 'download';

  // Sanitize the filename to avoid special characters
  if (fileName) {
    name = fileName;
  }

  // Sanitize the file name
  name = sanitizeFileName(name);

  const image = await fetch(fileUrl);
  const imageBlog = await image.blob();
  const imageURL = URL.createObjectURL(imageBlog);
  const link = document.createElement('a');
  link.href = imageURL;
  link.download = name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * Estimates the time required to read a given text.
 * @param {string} text The text to estimate reading time for.
 * @param {number} [speed=200] The reading speed in words per minute (default is 200).
 * @returns {string} A string representing the estimated reading time.
 */

export const estimateReadingTime = (text: string, speed: number = 200): string => {
  const words = text?.match(/\w+('\w+)?/g) || [];

  // Calculate the total number of words.
  const totalWords = words.length;

  // Estimate the reading time in minutes.
  const minutes = totalWords / speed;

  // Check if the reading time is less than a minute.
  if (minutes < 1) {
    return 'less than a minute';
  }

  const roundedMinutes = Math.ceil(minutes);
  const timeString = roundedMinutes <= 1 ? `${roundedMinutes} minute` : `${roundedMinutes} minutes`;

  return timeString;
};
export function formatTitle(input: string): string {
  // Define the characters you want to replace
  const charactersToReplace = /[_-]/g;

  // Replace the specified characters with a space
  return input?.replace(charactersToReplace, ' ');
}
