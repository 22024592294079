import { gql } from '@apollo/client';
const LEAGUEBYID = gql`
  query getLeagueById($id: ID!) {
    league(
      id: $id #    gender: "male"
    ) {
      title
      enableLicense
      enableRankings
      id
      type
      status
      sports {
        id
      }
      email
      website
      logo
      leagueCategory {
        id
        category {
          id
          name
        }
      }
      tournaments {
        id
      }
      athlete {
        id
      }
      owner {
        federation {
          id
          organizationName
          logo
          contactPhone
          contactEmail
          website
          user {
            id
          }
        }
      }
      members {
        id
        role {
          id
          user {
            id
            name
          }
          role {
            id
            name
          }
        }
        member {
          email
          id
          role
          name
        }
      }
      #  leagueCategory{
      #     category{
      #         name
      #         gender
      #     }
      # }
      # owner{
      #     federation{
      #         id
      #     }
      # }
    }
  }
`;
export default LEAGUEBYID;
