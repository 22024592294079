import React from 'react';
import { countryToAlpha2 } from 'country-to-iso';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
function GetFlag(country, style) {
  const isCountryNumber = Number(country);
  const code = countryToAlpha2(!Number.isNaN(isCountryNumber) ? '' : country);
  const fallbackImageSrc = '/images/flags/noFlag.svg';
  const imageSrc = code ? `/images/flags/${code.toLowerCase()}.svg` : fallbackImageSrc;
  return <LazyLoadImage alt={country} src={imageSrc} style={{ ...style }} effect="opacity" />;
}
export default GetFlag;
