import { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Typography, Stack } from '@material-ui/core';
import useAuth from '../../hooks/useAuth';
import NavSection from '../NavSection';
import toast from 'react-hot-toast';
import GetFlag from 'src/utils/getFlags';
import { ChevronLeft, ChevronRight, ConfirmationNumberOutlined, EqualizerOutlined, LocalFireDepartmentOutlined, Logout, PostAddOutlined, Settings, StarOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import { NAVBAR_HEIGHT } from './DashboardNavbar';
import CustomPaperCard from '../CustomComponents/CustomPaperCard';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Theme } from '@material-ui/core/styles';
import CustomAvatar from '../CustomComponents/CustomAvatar';

const DashboardRightDrawer = ({ open, setOpen }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout } = useAuth();

  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
    } catch (err) {
      console.error(err);
      toast.error('Unable to logout.');
    }
  };

  const sections = [
    // {
    //   title: "Settings",
    //   path: `/dashboard/edit-profile/${user?.id}`,
    //   icon: "/images/Default.svg",
    // },
    {
      title: `${t('My entries')}`,
      path: '/mycareer?tab=entries',
      icon: <ConfirmationNumberOutlined sx={{ fontSize: '20px' }} />,
    },
    {
      title: `${t('My matches')}`,
      path: '/mycareer?tab=matches',
      icon: <LocalFireDepartmentOutlined sx={{ fontSize: '20px' }} />,
    },
    {
      title: `${t('My rankings')}`,
      path: '/mycareer?tab=rankings',
      icon: <StarOutlined sx={{ fontSize: '20px' }} />,
    },
    {
      title: `${t('My leagues')}`,
      path: '/mycareer?tab=leagues',
      icon: <EqualizerOutlined sx={{ fontSize: '20px' }} />,
    },
    // {
    //   title: `${t('My Licenes')}`,
    //   path: '/mycareer#myLicenses',
    //   icon: '/images/updated/Athletes.svg',
    // },
    // {
    //   title: `${t('My Teams')}`,
    //   path: '/mycareer#myTeams',
    //   icon: '/images/updated/Icon/teams.svg',
    // },
    {
      title: `${t('Get Licence')}`,
      path: '/get-licence',
      icon: <PostAddOutlined sx={{ fontSize: '20px' }} />,
    },
    // {
    //   title: "Apply For New Role",
    //   path: "/apply-newrole",
    //   icon: "/images/updated/Icon/Clubs.svg",
    // },
    // {
    //   title: `${t("Tournaments")}`,
    //   icon: "/images/updated/details/Tournaments.svg",
    //   subItems: [
    //     {
    //       title: `${t("demo")}`,
    //       path: "/tournaments",
    //       icon: "/images/updated/details/Tournaments.svg",
    //     },
    //   ],
    // },
  ];

  const contentRef = useRef<HTMLDivElement>(null);
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const content = user && (
    <Stack
      ref={contentRef}
      alignItems="center"
      sx={{
        zIndex: 999,
        position: 'absolute',
        right: 0,
        height: '100%',
        width: open ? '250px' : '100%',
        transition: 'all 0.1s',
        animation: isTablet ? 'slideLeft 0.1s ease-out' : 'none',
        '@keyframes slideLeft': {
          '0%': {
            opacity: 0,
            width: '0',
          },
          '100%': {
            opacity: 1,
            width: '250px',
          },
        },
      }}
    >
      {
        open && (
          <Box sx={{ height: '100%', width: { xs: '100%', md: '30px' }, position: 'absolute', top: 0, right: '100%' }} onClick={isTablet ? () => setOpen(false) : undefined} />
        ) /* additional zone  */
      }
      <CustomPaperCard
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: open ? 'start' : 'center',
          borderBottom: 'none',
          borderRight: 'none',
          borderTop: 'none',
          width: '100%',
          minHeight: { xs: NAVBAR_HEIGHT.sm + 'px', md: NAVBAR_HEIGHT.lg + 'px' },
          position: 'relative',
          paddingInline: open ? '20px' : '10px',
          gap: '10px',
          overflow: 'visible',
        }}
      >
        <CustomButton
          variant="outline"
          size="medium"
          shape="circle"
          onClick={() => setOpen((prev) => !prev)}
          sx={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            transform: 'translate(-50%, 50%)',
            maxWidth: '28px',
            maxHeight: '28px',
            minWidth: '28px',
            minHeight: '28px',
            borderRadius: '8px',
            zIndex: 1,
            pointerEvents: isTablet ? 'auto' : 'none',
          }}
        >
          {!open ? <ChevronLeft fontSize="small" /> : <ChevronRight fontSize="small" />}
        </CustomButton>
        <CustomButton
          onClick={() => navigate(`/athlete/${user?.id}/${user?.name}-${user?.surname}?tab=overview`)}
          variant="text"
          size="auto"
          sx={{ position: 'relative', height: '32px', width: '32px' }}
        >
          <CustomAvatar src={user?.avatar} size={26} seed={user?.name + ' ' + user?.surname} />
          {GetFlag(user.nation, {
            width: '15px',
            height: '12px',
            position: 'absolute',
            left: '50%',
            bottom: 0,
            borderRadius: '3px',
            transform: 'translate(-50%, 25%)',
            border: '1px solid white',
          })}
        </CustomButton>
        {open && (
          <Stack justifyContent="center">
            <CustomButton
              onClick={() => navigate(`/athlete/${user?.id}/${user?.name}-${user?.surname}?tab=overview`)}
              variant="text"
              size="auto"
              sx={{ padding: 0, height: 'auto', width: 'fit-content' }}
            >
              <Typography color="#0F2927" variant="font15" sx={{ lineHeight: '100%' }}>
                {user.name} {user.surname}
              </Typography>
            </CustomButton>
            <CustomButton
              onClick={() => navigate(`/athlete/${user?.id}/${user?.name}-${user?.surname}?tab=overview`)}
              variant="text"
              size="auto"
              sx={{ padding: 0, height: 'auto', width: 'fit-content' }}
            >
              <Typography color="#86909F" variant="font21" sx={{ lineHeight: '100%', textTransform: 'capitalize' }}>
                {user.role}
              </Typography>
            </CustomButton>
          </Stack>
        )}
      </CustomPaperCard>
      <Stack sx={{ width: '100%', height: '100%', position: 'relative' }}>
        <CustomPaperCard
          sx={{
            right: 0,
            pt: '12px',
            flex: 1,
            width: '100%',
            borderRadius: 0,
            borderRight: 'none',
            borderBottom: 'none',
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          }}
        >
          <Stack
            sx={{
              alignItems: 'center',
              gap: '12px',
              paddingInline: '10px',
            }}
          >
            {sections.map((section) => (
              <NavSection revert key={section.title} pathname={location.pathname} openSideBarState={open} {...section} />
            ))}
          </Stack>
          <CustomPaperCard
            sx={{
              width: '100%',
              display: 'flex',
              pt: '12px',
              flexDirection: 'column',
              gap: '12px',
              alignItems: 'center',
              borderRadius: 0,
              borderLeft: 'none',
              borderRight: 'none',
              borderBottom: 'none',
              paddingInline: '10px',
            }}
          >
            <NavSection revert path="/userSettings" openSideBarState={open} title={t('Settings')} icon={<Settings sx={{ fontSize: '20px' }} />} />
            <NavSection openSideBarState={open} icon={<Logout sx={{ fontSize: '20px' }} />} title={t('Log out')} onClick={handleLogout} />
          </CustomPaperCard>
        </CustomPaperCard>
      </Stack>
    </Stack>
  );

  return (
    user &&
    (!isTablet ? (
      <Box onMouseLeave={() => setOpen(false)} onMouseEnter={() => setOpen(true)} sx={{ maxWidth: '60px', position: 'relative', width: '100%', zIndex: 999 }}>
        {content}
      </Box>
    ) : (
      open && content
    ))
  );
};

DashboardRightDrawer.propTypes = {
  setOpenSideBarState: PropTypes.func,
  openSideBarState: PropTypes.bool,
};

export default DashboardRightDrawer;
