import { gql } from "@apollo/client";

const CREATE_TOURNAMENT_REG_PAYMENT = gql`
  mutation createTournamentRegistrationPayment(
    $email: String
    $name: String
    $amount: Int
    $additionalFee: Float
    $successUrl: String
    $currency: String
    $registrationRequestId: Int
    $tournamentName: String
    $tournamentId: Int
    $categoryId: Int!
  ) {
    createTournamentRegistrationPayment(
      createRegistrationPaymentInput: {
        email: $email
        name: $name
        amount: $amount
        additionalFee: $additionalFee
        successUrl: $successUrl
        currency: $currency
        registrationRequestId: $registrationRequestId
        tournamentName: $tournamentName
        tournamentId: $tournamentId
        categoryId: $categoryId
      }
    )
  }
`;

export default CREATE_TOURNAMENT_REG_PAYMENT;
