import { gql } from "@apollo/client";
const USERPARENTS = gql`
  query userParents($filter: ListUserParentInput) {
    userParents(filter: $filter) {
      id
      user {
        id
        email
      }
    }
  }
`;
export default USERPARENTS;
