import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import Cookies from 'js-cookie';
import { onError } from '@apollo/client/link/error';
import { eventEmitter } from 'src/constants';

const cache = new InMemoryCache({
  typePolicies: {
    // CategorySetting: { keyFields: ['isRegistered']},

    Query: {
      fields: {
        //  * NEED TO DEFINE WHAT TYPE OF DATA WILL BE MERGED\
        licenses: {
          merge: (existing: any, incoming: any) => incoming,
        },
        userLicenses: {
          merge: (existing: any, incoming: any) => incoming,
        },
        createTournamentRegistration: {
          merge: (existing: any, incoming: any) => incoming,
        },
        // tournament: {
        //   merge: (existing: any, incoming: any) => existing,
        // },
        // tournamentTopFivePlayers: {
        //   merge: (existing: any, incoming: any) => incoming,
        // },
      },
    },
  },
});
// const URL = "https://tornated-dev-094f13c08ec4.herokuapp.com/graphql";
const URL = process.env.REACT_APP_BACKEND_URL;
// const URL = 'https://vertexo-cd8e69cd5c9d.herokuapp.com/graphql';
// const URL = 'https://api.tournated.com/graphql';
// const URL = 'https://data-migration-tournated-dev-e8f5bab9d16d.herokuapp.com/graphql';
// const URL = 'https://258c-39-60-237-81.ngrok-free.app/graphql';

// const URL = 'http://localhost:3000/graphql'

const authLink = setContext((_, { headers }) => {
  // * GET THE AUTHENTICATION TOKEN FROM LOCAL STORAGE IF IT EXISTS
  const token = Cookies.get('token');
  // * RETURN THE HEADERS TO THE CONTEXT SO HTTP-LINK CAN READ THEM
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
      'apollo-require-preflight': true,
    },
  };
});

const httpLink = createUploadLink({
  uri: URL,
});
const errorLink = onError(({ networkError }) => {
  if (networkError) {
    console.error('Network error occurred:', networkError);
    eventEmitter.emit('serverError'); // Emit an event on network error
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache,
});

export function refetchAllQueries() {
  client.refetchQueries({
    include: 'all',
  });
}

export default client;
