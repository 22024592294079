import { useEffect } from 'react';
import type { FC } from 'react';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import './i18n';
import RTL from './components/RTL';
import SplashScreen from './components/SplashScreen';
import { gtmConfig } from './config';
import useAuth from './hooks/useAuth';
import useSettings from './hooks/useSettings';
import gtm from './lib/gtm';
import routes from './routes';
import { createCustomTheme } from './theme';
import './assets/fonts/montserrat/stylesheet.css';
import './assets/fonts/roboto/stylesheet.css';
import './assets/fonts/eudoxus-sans/stylesheet.css';
import './assets/fonts/Inter/stylesheet.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.css';
import Cookies from 'js-cookie';
import Loading from './components/Loading';
import { eventEmitter } from './constants';

const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const tokenParam = new URLSearchParams(window.location.search).get('token');
  const openLoginModal = new URLSearchParams(window.location.search).get('openLogin');
  const openRegModal = new URLSearchParams(window.location.search).get('openRegister');
  const openForgetModal = new URLSearchParams(window.location.search).get('openReset');
  // Set up an effect to listen for the server error event
  useEffect(() => {
    const handleServerError = () => {
      auth?.setServerCrashModalOpen(true);
    };

    eventEmitter.on('serverError', handleServerError);

    // Cleanup the event listener on component unmount
    return () => {
      eventEmitter.off('serverError', handleServerError);
    };
  }, []);
  useEffect(() => {
    if (tokenParam) {
      Cookies.set('token', tokenParam);
    }
  }, [tokenParam]);

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  useEffect(() => {
    gtm.initialize(gtmConfig);

    let link = (document?.querySelector("link[rel='icon']") as HTMLLinkElement) || document?.createElement('link');
    if (!link) {
      link = document?.createElement('link');
      link.rel = 'icon';
      document?.getElementsByTagName('head')[0]?.appendChild(link);
    }
    link.href = auth?.platformData?.lightLogo || process.env.REACT_APP_LOGO_ICON;
  }, [auth?.platformData?.lightLogo, process.env.REACT_APP_LOGO_ICON]);

  useEffect(() => {
    const isRestrictedPath = location.pathname.startsWith('/userSettings') || location.pathname === '/mycareer' || location.pathname === '/get-licence';
    if (auth?.user && !auth?.isAuthenticated) {
      if (isRestrictedPath) {
        auth?.setLoginModalOpen(true);
      }
    }
    if (!auth?.isAuthenticated) {
      if (openLoginModal) {
        auth?.setLoginModalOpen(true);
      }
      if (openRegModal) {
        auth?.setSignupModalOpen(true);
      }
      if (openForgetModal) {
        auth?.setResetModalOpen(true);
      }
    }
  }, [location.pathname, auth?.isAuthenticated]);

  let metaThemeColor = document?.querySelector('meta[name="theme-color"]');
  if (!metaThemeColor) {
    metaThemeColor = document?.createElement('meta');
    metaThemeColor?.setAttribute('name', 'theme-color');
    document?.getElementsByTagName('head')[0]?.appendChild(metaThemeColor);
  }

  // Set the content of the meta tag to the primary color of the Material-UI theme
  metaThemeColor?.setAttribute('content', process.env.REACT_APP_PRIMARY_COLOR);
  document?.documentElement?.style?.setProperty('--primary-color', process.env.REACT_APP_PRIMARY_COLOR);

  useEffect(() => {
    // Check if the user has visited before
    const hasVisitedBefore = localStorage.getItem('hasVisitedBefore');

    if (!openForgetModal && !openLoginModal && !openRegModal && !hasVisitedBefore) {
      // If not, show the modal and set the flag in local storage
      auth?.setWelcomeModalOpen(true);
      localStorage.setItem('hasVisitedBefore', 'true');
    }
  }, []);
  useEffect(() => {
    document.title = auth?.platformData?.title ?? '';
  }, [auth?.platformData]);
  let decodedMCode = null;
  const isMaintenanceEnabled = process.env.REACT_APP_MAINTENANCE_ENABLED === 'true';

  try {
    const mCode = Cookies.get('mCode');
    decodedMCode = mCode ? window.atob(mCode) : null;
  } catch (error) {
    console.error('Error decoding mCode:', error);
  }

  useEffect(() => {
    if (isMaintenanceEnabled && (!decodedMCode || decodedMCode !== process.env.REACT_APP_MAINTENANCE_CODE)) {
      navigate('/maintenance-code');
    }
  }, [isMaintenanceEnabled, decodedMCode, navigate]);
  if (auth?.platformLoading) {
    return <Loading height="100vh" />;
  }
  return (
    <ThemeProvider theme={theme}>
      <RTL direction={settings.direction}>
        <CssBaseline />
        <Toaster position="top-center" />
        {/* <SettingsDrawer /> */}
        {auth.isInitialized ? content : <SplashScreen />}
      </RTL>
    </ThemeProvider>
  );
};

export default App;
