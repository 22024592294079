import { Box, Stack } from '@material-ui/core';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import CustomPopover from 'src/components/CustomComponents/CustomPopover';
import React, { ComponentProps, ReactNode, useMemo } from 'react';
import ChevronDown from '../../icons/ChevronDown';
import ClearButton from './ClearButton';

type Option<T> = {
  value: T;
  title: string;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  disabled?: boolean;
};

type CustomSelectProps<T> = {
  placeholder?: string;
  options: Option<T>[];
  selectedValue?: T;
  onChange: (value: T) => void;
  selected?: boolean;
  error?: boolean;
  helperText?: string;
  buttonProps?: Partial<ComponentProps<typeof CustomButton>>;
  onClear?: () => void;
};

const CustomSelect = <T extends string | number>({ selectedValue, options, placeholder, error, helperText, onChange, selected, buttonProps = {}, onClear }: CustomSelectProps<T>) => {
  const selectedOption = useMemo(() => options?.find((option) => option.value === selectedValue), [selectedValue, options, placeholder]);

  const handleMenuItemClick = (value: T) => {
    onChange(selectedValue === value ? null : value);
  };

  return (
    <CustomPopover
      modalOnMobile
      disablePadding
      triggerEl={(open) => (
        <CustomButton
          size="small"
          variant="outline"
          selected={open || selected}
          {...buttonProps}
          error={error}
          helperText={helperText}
          sx={{
            gap: '4px',
            justifyContent: 'space-between',
            flexShrink: '0',
            position: 'relative',
            ...buttonProps.sx,
          }}
        >
          {selectedOption?.leftIcon}
          <Box sx={{ flex: 1, textAlign: 'start', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '200px' }}>{selectedOption?.title ?? placeholder}</Box>
          <Stack flexDirection="row" gap="4px" alignItems="center">
            <ChevronDown sx={{ fontSize: '14px' }} />
            {selectedValue && onClear && <ClearButton onClear={onClear} />}
          </Stack>
          {selectedOption?.rightIcon}
        </CustomButton>
      )}
    >
      {(onClose) => (
        <Box sx={{ flex: 1, overflow: 'auto', maxHeight: { xs: 'none', sm: '240px' }, padding: '4px', minWidth: '100px' }}>
          {options?.map((option) => (
            <li key={option.value} style={{ all: 'unset' }}>
              <CustomButton
                variant="ghost"
                size="medium"
                sx={{ gap: '4px', width: '100%', overflow: 'hidden', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', height: '30px' }}
                value={option.value}
                onClick={() => {
                  onClose();
                  handleMenuItemClick(option.value);
                }}
                selected={selectedValue === option.value}
                disabled={option.disabled}
              >
                <Box sx={{ display: 'flex', gap: '4px', textOverflow: 'ellipsis', alignItems: 'center', flex: 1 }}>
                  {option?.leftIcon}
                  {option.title}
                </Box>
                {option?.rightIcon}
              </CustomButton>
            </li>
          ))}
        </Box>
      )}
    </CustomPopover>
  );
};

export default CustomSelect;
