import { gql } from "@apollo/client";

const GET_USER_BY_EMAIL = gql`
  query user($query: FindUserInput!) {
    user(query: $query) {
      id
      name
      email
      fideId
    }
  }
`;

export default GET_USER_BY_EMAIL;
